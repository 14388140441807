import RichTextInput from 'ra-input-rich-text'
import {
    ArrayInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin'

const AboutUsEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <TabbedForm>
            <FormTab label="Header">
                    <RichTextInput source="section1.title" label="Title" />
                    <RichTextInput source="section1.description" label="Description" />
                    <ImageInput

                        accept="image/*"
                        source="section1.image"
                        multiple={false}
                        label="Banner Image"
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <ImageInput

                        accept="image/*"
                        source="section1.videoImage"
                        multiple={false}
                        label="Video Image"
                    >
                    <ImageField source="src" title="title" label="Video Image" />
                    </ImageInput>
                    <TextInput source='section1.videoLink' label="Video Url" />
            </FormTab>
            <FormTab label="Milestones">
                <TextInput source="section3.title" label="Title" />
                <ArrayInput
                    source="section3.milestonesData"
                    label="Milestones"
                >
                    <SimpleFormIterator>
                        <TextInput source="year" label="Year" />
                        <TextInput source="title" label="Main Text" />
                        <TextInput source="subtext" label="Sub Text" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Felicitations">
                <ArrayInput
                    source="section2.quotes"
                    label="Acheivements"
                >
                    <SimpleFormIterator>
                        <ImageInput
                            {...props}
                            accept="image/*"
                            source="image"
                            multiple={false}
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                        <TextInput source="subheading" label="Heading" />
                        <TextInput source="content" label="Content" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default AboutUsEdit
