import {
    ArrayField,
    Show,
    TabbedShowLayout,
    ListProps,
    Tab,
    ImageField,
    SimpleShowLayout,
    TextField,
    Datagrid,
} from 'react-admin'

const AboutUsList = (props: ListProps) => {
    return (
        <Show {...props} title={'About Us View'} >
            <TabbedShowLayout>
                <Tab label="Header">
                    <SimpleShowLayout>
                        <TextField source="section1.title" label="Title" />
                        <TextField source="section1.description" label="Description" />
                        <ImageField source='section1.image.src' label="Header Image" />
                        <ImageField source='section1.videoImage.src' label="Video Image" />
                        <TextField source='section1.videoLink' label="Video Link" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Milestones">
                    <TextField source="section3.title" label="Title" />
                    <SimpleShowLayout>
                        <ArrayField source="section3.milestonesData" label="Milestones" >
                            <Datagrid>
                                <TextField source="year" label="Year" />
                                <TextField source="title" label="Main Text" />
                                <TextField source="subtext" label="Sub Text" />
                            </Datagrid>
                        </ArrayField>
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Felicitations">
                    <SimpleShowLayout>
                        <ArrayField source="section2.quotes" label="Felicitations" >
                            <Datagrid>
                                <ImageField source='image.src' label={''} />
                                <TextField source="content" label="Content" />
                                <TextField source="subheading" label="Subheading" />
                            </Datagrid>
                        </ArrayField>
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default AboutUsList
